.action-card {
  min-height: 100px;
  min-width: 240px;
  width: 100%;
  color: white;
  font-family: 'Roboto';
  display: flex;
  justify-content: center;
  border-radius: 8px;
  border: 1px solid var(--border);
  text-align: center;
  position: relative;
}

.action-card-title {
  font-size: 24px;
  color: white;
  padding-top: 20px;
  padding-left: 20px;
  padding-right: 20px;
}

.action-card-text {
  color: #797f91;
  font-size: 18px;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 20px;
  padding-right: 20px;
}

.action-card-button {
  margin-bottom: 24px;
  margin-left: auto;
  margin-right: auto;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}
