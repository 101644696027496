:root {
  --background: #140f1a;
  --status-bar-background: #1d1326;
  --solworks-background: #1d1326;
  --grey: #797f91;
  --primary: #e42575;
  --alternative-primary: #0051c3;
  --border: #261d2b;
  --lm-background: white;
  --lm-border: rgb(153, 153, 153);
  --grey-border: #261d2b;
  --lm-selected-menu: #EBF4FF;
  --lm-selected-menu-border: #B9D6FF;
  font-family: Roboto, sans-serif !important;
  --font: 'Roboto', 'sans-serif' !important;
}

.body-wrapper {
  padding-top: 0px;
  padding-left: 48px;
  padding-right: 48px;
  padding-bottom: 120px;
  color: white;
}

@media (max-width: 640px) {
  .body-wrapper {
    padding-top: 34px;
    padding-left: 24px;
    padding-right: 24px;
  }
}

.roboto {
  font-family: Roboto, sans-serif !important;
}

.font-16 {
  font-size: 16px !important;
}

.font-bold {
  font-weight: bold !important;
}

.normal-line-style {
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}

.white-text {
  color: white !important;
}

.inline-contents {
  display: flex;
}


.no-link-hover:hover {
  text-decoration: none;
  -webkit-transform: translate(0, -3px);
  -ms-transform: translate(0, -3px);
  transform: translate(0, -3px);
}

.link-hover:hover {
  text-decoration: underline dashed;
  -webkit-transform: translate(0, -3px);
  -ms-transform: translate(0, -3px);
  transform: translate(0, -3px);
}

body.sb-show-main {
  background: transparent !important;
}

.glow-on-hover {
  outline: none;
  cursor: pointer;
  position: relative;
  z-index: 0;
  border-radius: 8px;
}

.glow-on-hover.px18 {
  border-radius: 8px;
}

.glow-on-hover:before {
  content: '';
  background: linear-gradient(45deg,#ff00c8, #ff0000, #eb6841, yellow, #ff00c8);
  position: absolute;
  top: -2px;
  left: -2px;
  background-size: 400%;
  z-index: -1;
  filter: blur(5px);
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  animation: glowing 20s linear infinite alternate;
  -webkit-animation: glowing 20s linear infinite alternate;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  -webkit-transition: opacity 0.3s ease-in-out;
  border-radius: 8px;
}

.glow-on-hover.colors-only:before {
  content: '';
  background: linear-gradient(45deg, #ff00c8, #ff0000, var(--primary), #e34a27);
  position: absolute;
  top: -2px;
  left: -2px;
  background-size: 400%;
  z-index: -1;
  filter: blur(5px);
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  animation: glowing 15s linear infinite;
  -webkit-animation: glowing 15s linear infinite;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  -webkit-transition: opacity 0.3s ease-in-out;
  border-radius: 8px;
}

.glow-on-hover.px18:before {
  border-radius: 8px;
}

.glow-on-hover:active {
  color: #fff;
}

.glow-on-hover:hover:before {
  opacity: 1;
}

.glow-on-hover:after {
  z-index: -1;
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  background: transparent;
  left: 0;
  top: 0;
  border-radius: 8px;
}

.glow-on-hover.px18:after {
  border-radius: 8px;
}

.glow-on-hover.no-bg:after {
  background: transparent;
}

.glow-on-hover.white-bg:after {
  background: white;
}

.glow-on-hover.pink-bg:after {
  background: var(--primary);
}

.glow-on-hover.bg:after {
  background: var(--background);
}

@keyframes glowing {
  0% {
    background-position: 0 0;
  }

  50% {
    background-position: 400% 0;
  }

  100% {
    background-position: 0 0;
  }
}

.no-link-hover {
  text-decoration: none;
  -webkit-transition: 150ms ease-in-out;
  transition: 150ms ease-in-out;
}

.link-hover {
  text-decoration: underline dashed;
  -webkit-transition: 150ms ease-in-out;
  transition: 150ms ease-in-out;
}

.secondary-button {
  -webkit-transition: 150ms ease-in-out;
  transition: 150ms ease-in-out;
  width: 158px;
  min-width: 60px;
  height: 48px;
  min-height: 48px;
  border: solid 1px #24182f;
  background-color: none;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  border-radius: 8px;
}

.secondary-button-text {
  font-family: var(--font);
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #eae4e4;
}

.secondary-button:hover {
  -webkit-transform: translate(0, -2px);
  -ms-transform: translate(0, -2px);
  transform: translate(0, -2px);
  cursor: pointer;
  background-color: rgba(29, 19, 38, 0.6);
}

.rise-on-hover-150 {
  -webkit-transition: 150ms ease-in-out;
  transition: 150ms ease-in-out;
}

.rise-on-hover-300 {
  -webkit-transition: 300ms ease-in-out;
  transition: 300ms ease-in-out;
}

.rise-on-hover-150:hover {
  -webkit-transform: translate(0, -2px);
  -ms-transform: translate(0, -2px);
  transform: translate(0, -2px);
}

.rise-on-hover-300:hover {
  -webkit-transform: translate(0, -2px);
  -ms-transform: translate(0, -2px);
  transform: translate(0, -2px);
}


@media (max-width: 580px) {
  .secondary-button {
    width: 100%;
  }
}

.pink-text {
  color: var(--primary);
}

.grey-text {
  color: var(--grey);
  font-family: var(--font);
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
}

.white-text-on-hover {
  -webkit-transition: 150ms ease-in-out;
  transition: 150ms ease-in-out;
}

.white-text-on-hover:hover {
  color: white;
}

.link {
  color: white;
  text-decoration: none;
}

a {
  color: var(--grey);
  text-decoration: underline dashed;
}
